<template>
	<div class="container">
		<div class="ewm page-sub-box">
			<div class="flex flex-col gap-y-6 divide-y">
				<!-- 1차 -->
				<DxValidationGroup ref="surveyValidationGroup">
					<div class="flex gap-x-6">
						<div class="w-1/2">
							<div class="sub_title_txt my-4">
								<h2>설문 설정</h2>
							</div>
							<table class="table_form line-bin">
								<colgroup>
									<col style="width:130px;" />
									<col style="width:auto;" />
								</colgroup>
								<tbody>
									<tr>
										<th scope="row">
											<label for="label5"> 설문 제목 <span class="icon_require">필수항목</span> </label>
										</th>
										<td class="clearfix">
											<DxTextBox
												v-model="formData.surNm"
												:styling-mode="config.stylingMode"
												width="400"
												class="mar_ri10"
												:max-length="limitNumberTexts.maxLengths.surNm"
												@key-up="$_checkLimitTextLength($event, formData, limitNumberTexts, 'surNm')"
											>
												<DxValidator>
													<DxRequiredRule message="설문제목은 필수입니다." />
												</DxValidator>
											</DxTextBox>
										</td>
									</tr>
									<tr>
										<th scope="row">
											<label for="label5"> 설문 기간 <span class="icon_require">필수항목</span> </label>
										</th>
										<td>
											<div>
												<DxDateBox
													v-model="formData.surStartDt"
													:styling-mode="config.stylingMode"
													:width="120"
													type="date"
													dateSerializationFormat="yyyy-MM-ddTHH:mm:ss"
													display-format="yyyy.MM.dd"
													:max="formData.surEndDt"
													maxLength="10"
													invalid-date-message="입력 데이터가 유효하지 않습니다."
													@value-changed="onChangeDay"
												>
													<DxValidator>
														<DxRequiredRule message="설문 기간은 필수입니다." />
													</DxValidator>
												</DxDateBox>
											</div>
											<div class="ui-datepicker period">
												<span class="dash">~</span>
											</div>
											<div>
												<DxDateBox
													v-model="formData.surEndDt"
													:styling-mode="config.stylingMode"
													:width="120"
													type="date"
													dateSerializationFormat="yyyy-MM-ddTHH:mm:ss"
													display-format="yyyy.MM.dd"
													:min="formData.surStartDt"
													maxLength="10"
													invalid-date-message="입력 데이터가 유효하지 않습니다."
													@value-changed="onChangeDay"
												>
													<DxValidator>
														<DxRequiredRule message="설문 기간은 필수입니다." />
													</DxValidator>
												</DxDateBox>
											</div>
										</td>
									</tr>
									<!-- <tr>
                                    <th scope="row">
                                        <label for="label5">
                                            설문 대상자
                                        </label>
                                    </th>
                                    <td>
                                        <div class="space-y-4">
                                            <DxButton text="+ 선택" type="button" :height="30" @click="onOpen" />
                                            <DxRadioGroup
                                                class="radiobox"
                                                :items="config.targetOpen"
                                                v-model="formData.surveyTargets"
                                            />
                                        </div>
                                    </td>
                                </tr> -->
									<tr>
										<th scope="row">
											<label for="label5"> 결과 공개 여부 <span class="icon_require">필수항목</span> </label>
										</th>
										<td>
											<DxRadioGroup
												class="radiobox"
												layout="horizontal"
												:items="config.resultOpenFl"
												v-model="formData.resultOpenFl"
											>
												<DxValidator>
													<DxRequiredRule message="결과 공개 여부는 필수입니다." />
												</DxValidator>
											</DxRadioGroup>
										</td>
									</tr>
									<tr>
										<th scope="row">
											<label for="label5">
												설문 안내 문구
											</label>
										</th>
										<td>
											<DxTextArea
												v-model="formData.sheetName"
												:styling-mode="config.stylingMode"
												:width="400"
												:height="70"
												class="mar_ri10 alB"
												:max-length="limitNumberTexts.maxLengths.sheetName"
												@key-up="$_checkLimitTextLength($event, formData, limitNumberTexts, 'sheetName')"
											>
											</DxTextArea>
										</td>
									</tr>
								</tbody>
							</table>
						</div>

						<div class="w-1/2">
							<div class="sub_title_txt my-4">
								<h2>설문 대상자<span class="icon_require">필수항목</span></h2>
							</div>
							<div>
								<div class="flex justify-start">
									<DxButton
										:disabled="!config.canModify"
										text="대상자 추가"
										type="button"
										class="btn_XS white light_filled add2"
										:height="30"
										@click="onOpen"
									/>
									<DxButton
										:disabled="!config.canModify"
										text="삭제"
										type="button"
										class="btn_XS white light_filled trash"
										:height="30"
										@click="onTargetDeleteData"
									/>
								</div>
								<div class="mt-4">
									<DxDataGrid
										class="grid-box"
										ref="targetGridData"
										:data-source="targetGrid.dataSource"
										:allow-column-resizing="true"
										:column-resizing-mode="'nextColumn'"
										:show-borders="false"
										:show-column-headers="true"
										:show-column-lines="true"
										:show-row-lines="true"
										:row-alternation-enabled="false"
										:hover-state-enabled="true"
										:word-wrap-enabled="true"
										:no-data-text="this.$_msgContents('CMN_NO_DATA')"
										:selected-row-keys="targetGrid.selectedRowKeys"
										@row-click="onRowClick"
										@cell-hover-changed="onCellHoverChanged"
										width="100%"
										height="220"
									>
										<DxLoadPanel :enabled="true" />
										<DxScrolling mode="standard" />
										<DxFilterRow :visible="false" />
										<DxSelection
											mode="multiple"
											:allow-select-all="true"
											show-check-boxes-mode="always"
											select-all-mode="allPages"
										/>

										<DxColumn
											caption="부서정보"
											data-field="deptNmPath"
											:allowEditing="false"
											:allow-sorting="false"
											alignment="center"
										/>
										<DxColumn
											caption="대상자[ID]"
											data-field="agtid"
											:allowEditing="false"
											:allow-sorting="false"
											alignment="center"
											:calculate-display-value="rowData => `${rowData.agtNm}[${rowData.agtId}]`"
										/>
									</DxDataGrid>
								</div>
							</div>
						</div>
					</div>

					<!-- 2차 -->
					<!-- <div>
                    <h3 class="text-xl font-medium my-4">설문지 설정</h3>
                    <table class="table_form line-bin">
                        <colgroup>
                            <col style="width:130px;" />
                            <col style="width:auto;" />
                        </colgroup>
                        <tbody>
                            <tr>
                                <th scope="row">
                                    <label for="label5">
                                        설문 안내 문구
                                    </label>
                                </th>
                                <td>
                                    <DxTextArea v-model="formData.sheetName" :styling-mode="config.stylingMode" :width="770"
                                        :height="70" class="mar_ri10 alB" />
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div> -->

					<!-- 3차 -->
					<div>
						<div class="sub_title_txt my-4">
							<h2>설문지 설정</h2>
						</div>
						<!-- <div v-scroll-bottom class="maxHeight overflow-y-auto mt-4 flex flex-col gap-y-6" id="surveyQuestionsContainer"> -->
						<div class="maxHeight overflow-y-auto mt-4 flex flex-col gap-y-6" id="surveyQuestionsContainer">
							<template v-for="(item1, index1) in formData.surveyQuestions">
								<div class="thi" :key="index1" v-if="item1.delFl === 'N'">
									<table class="table_form line-bin tableThi">
										<caption>
											<strong>설문보기</strong>
										</caption>
										<colgroup>
											<col style="width:130px;" />
											<col style="width:auto;" />
										</colgroup>

										<tbody>
											<tr>
												<th scope="row">
													<label for="label5"> 질문 <span class="icon_require">필수항목</span> </label>
												</th>
												<td>
													<DxTextBox
														v-model="item1.questionNm"
														:width="770"
														:styling-mode="config.stylingMode"
														class="mar_ri10"
														:max-length="limitNumberTexts.maxLengths.questionNm"
														@key-up="$_checkLimitTextLength($event, item1, limitNumberTexts, 'questionNm')"
													>
														<DxValidator>
															<DxRequiredRule message="질문은 필수입니다." />
														</DxValidator>
													</DxTextBox>

													<div style="margin-left: 30px;">
														<span v-if="index1 !== 0" style="margin-left: 10px;">
															<DxButton
																class="btn_XS white light_filled"
																text="문항 삭제"
																:height="30"
																:disabled="!config.canModify"
																@click="onRemoveItems(index1)"
															/>
														</span>
													</div>
												</td>
											</tr>
											<tr>
												<th scope="row">
													<label for="label5"> 설문 문항 타입 <span class="icon_require">필수항목</span> </label>
												</th>
												<td>
													<DxSelectBox
														placeholder="문항 타입 선택"
														:items="codes.questionGroupCd.dataSource"
														display-expr="codeNm"
														value-expr="codeId"
														v-model="item1.questionGroupCd"
														:styling-mode="config.stylingMode"
														class="mar_ri10"
														width="150"
														@value-changed="onQuestionGroupCdChange(index1)"
													>
														<DxValidator>
															<DxRequiredRule message="설문 문항 타입은 필수입니다." />
														</DxValidator>
													</DxSelectBox>
													<!-- FIXME : (EWM) 공통코드 하드 코딩 -->
													<template v-if="item1.questionGroupCd && item1.questionGroupCd == 1089">
														<DxSelectBox
															placeholder="객관형 선택"
															:items="codes.questionTypeChoiceCd"
															display-expr="codeNm"
															value-expr="codeId"
															v-model="item1.questionTypeCd"
															:styling-mode="config.stylingMode"
															class="mar_ri10"
															width="150"
														>
															<DxValidator>
																<DxRequiredRule message="객관형 선택은 필수입니다." />
															</DxValidator>
														</DxSelectBox>
													</template>
													<template v-if="item1.questionGroupCd && item1.questionGroupCd == 1091">
														<DxSelectBox
															placeholder="점수형 선택"
															:items="codes.questionTypeScoreCd"
															display-expr="codeNm"
															value-expr="codeId"
															v-model="item1.questionTypeCd"
															:styling-mode="config.stylingMode"
															class="mar_ri10"
															width="150"
														>
															<DxValidator>
																<DxRequiredRule message="점수형 선택은 필수입니다." />
															</DxValidator>
														</DxSelectBox>
													</template>
													<DxCheckBox class="checkbox" v-model="item1.importantAnswerFl" text="필수 답변">
													</DxCheckBox>
												</td>
											</tr>
											<template v-if="item1.questionGroupCd === 1089">
												<tr>
													<th scope="row">
														<label for="label5"> 보기 <span class="icon_require">필수항목</span> </label>
													</th>
													<td>
														<div class="sub_new_style01 sub_ui_box2 locker_setting_list" style="width: 940px">
															<table class="table_list">
																<caption>
																	<strong>보기 설정</strong>
																</caption>
																<colgroup>
																	<col style="width:60px" />
																	<col style="width:auto" />
																	<col style="width:12%" />
																</colgroup>
																<thead>
																	<tr>
																		<th scope="col">순서</th>
																		<th scope="col">문항</th>
																		<th scope="col">추가/삭제</th>
																	</tr>
																</thead>
																<tbody>
																	<template v-for="(item2, index2) in item1.surveyQuestionRegs">
																		<tr :key="index2" v-if="item2.delFl === 'N'">
																			<td>{{ item2.answerOrd }}</td>
																			<td>
																				<DxTextBox
																					placeholder="보기 입력"
																					v-model="item2.questionView"
																					:styling-mode="config.stylingMode"
																					:width="600"
																					class="mar_ri10"
																					:max-length="limitNumberTexts.maxLengths.questionView"
																					@key-up="
																						$_checkLimitTextLength(
																							$event,
																							item2,
																							limitNumberTexts,
																							'questionView',
																						)
																					"
																				>
																					<DxValidator>
																						<DxRequiredRule message="보기는 필수입니다." />
																					</DxValidator>
																				</DxTextBox>
																			</td>
																			<td>
																				<template v-if="index2 === 0">
																					<DxButton
																						text="추가"
																						class="btn_XS white light_filled add3"
																						:width="60"
																						:height="30"
																						:disabled="!config.canModify"
																						@click="onAddAnswer(index1)"
																					>
																					</DxButton>
																				</template>
																				<template v-else>
																					<DxButton
																						text="삭제"
																						class="btn_XS white light_filled del1"
																						:width="60"
																						:height="30"
																						:disabled="!config.canModify"
																						@click="onRemoveAnswer(index1, { item2, index2 })"
																					>
																					</DxButton>
																				</template>
																			</td>
																		</tr>
																	</template>
																</tbody>
															</table>
														</div>
													</td>
												</tr>
											</template>
										</tbody>
									</table>
								</div>
							</template>
						</div>
						<div class="flex justify-center mt-4 mb-8">
							<DxButton
								class="btn_XS default filled"
								:disabled="!config.canModify"
								text="설문 항목 추가"
								:height="40"
								@click="onAddItems"
							/>
						</div>
					</div>
				</DxValidationGroup>
			</div>
		</div>

		<section class="terms bottom-btn-box">
			<div class="bottom-btn-wrap">
				<DxButton
					text="저 장"
					:disabled="!config.canModify"
					:width="120"
					:height="40"
					class="default filled txt_S medium"
					:use-submit-behavior="true"
					@click="onSaveData(0)"
				/>
				<DxButton
					text="임시 저장"
					:disabled="!config.canModify"
					:width="120"
					:height="40"
					class="default filled txt_S medium"
					@click="onSaveData(1)"
				/>
				<DxButton text="취 소" :width="120" :height="40" class="white filled txt_S medium" @click="onCancelFormData" />
			</div>
		</section>

		<!-- Agent Modal -->
		<modal-add-agent
			:isOpen="modal.agent.popupVisible"
			:showModalTitle="true"
			:selectedIdList="modal.agent.selectedIdList"
			@closeModal="onClose(false)"
			@saveModal="onSave"
		/>
		<!-- Agent Modal -->
	</div>
</template>

<script>
import { DxDateBox } from 'devextreme-vue/date-box';
import { DxButton } from 'devextreme-vue/button';
import { DxTextBox } from 'devextreme-vue/text-box';
import { DxSelectBox } from 'devextreme-vue/select-box';
import { DxCheckBox } from 'devextreme-vue/check-box';
import { DxRadioGroup } from 'devextreme-vue/radio-group';
import { DxTextArea } from 'devextreme-vue/text-area';
import { isSuccess } from '@/plugins/common-lib';
import { DxDataGrid, DxColumn, DxFilterRow, DxLoadPanel, DxScrolling, DxSelection } from 'devextreme-vue/data-grid';
import { DxValidator, DxRequiredRule } from 'devextreme-vue/validator';
import { DxValidationGroup } from 'devextreme-vue/validation-group';
import ModalAddAgent from '@/components/ewm/hr/modal-add-agent.vue';

let vm = this;
let isSelectData = false;

export default {
	components: {
		DxDateBox,
		DxButton,
		DxTextBox,
		DxSelectBox,
		DxCheckBox,
		DxRadioGroup,
		DxTextArea,
		DxColumn,
		DxDataGrid,
		DxFilterRow,
		DxLoadPanel,
		DxScrolling,
		DxSelection,
		ModalAddAgent,

		DxValidator,
		DxRequiredRule,
		DxValidationGroup,
	},
	props: {},
	watch: {},
	directives: {
		'scroll-bottom': {
			/* inserted: function (el) {
                el.scrollTop = el.scrollHeight;
            }, */
			componentUpdated: function(el) {
				if (isSelectData) {
					el.scrollTop = el.scrollHeight;
				}
			},
		},
	},
	data() {
		return {
			ewmCodes: this.$_enums.ewm,
			config: {
				updateYn: false, //등록 or 수정 여부 체크
				stylingMode: 'outlined', //[outlined, filled, underlined]
				resultOpenFl: ['공개', '비공개'],
				targetOpen: ['부서', '상담사'],
				canModify: true,
			},
			codes: {
				questionGroupCd: {
					dataSource: [],
					displayExpr: 'codeNm',
					valueExpr: 'codeId',
				},
				questionTypeCd: {
					dataSource: [],
					displayExpr: 'codeNm',
					valueExpr: 'codeId',
				},
				questionTypeChoiceCd: [],
				questionTypeScoreCd: [],
			},
			modal: {
				agent: {
					popupVisible: false,
					selectedIdList: [],
					targetSelectedIdList: [],
				},
			},
			limitNumberTexts: {
				textLengths: {},
				maxLengths: {
					surNm: 100,
					sheetName: 100,
					questionNm: 100,
					questionView: 100,
				},
			},
			targetGrid: {
				dataSource: [],
				selectedRowsData: [],
				selectedRowKeys: [],
			},
			formData: {
				id: null, //id
				surNm: '', //설문 제목
				surProcessCd: null, //진행상태
				surStartDt: '', //설문 시작기간
				surEndDt: '', //설문 끝기간
				resultOpenFl: '', //결과 공개 여부
				sheetId: null, //설문 안내 id
				sheetName: '', //설문 안내 문구
				surveyTargets: [], //설문 대상자
				surveyQuestions: [
					//질문
					/* {
                        id: null, //질문 id
                        questionNm: '', //질문 제목
                        questionTypeCd: null,  //설문 문항 타입 하위
                        questionGroupCd: null, //설문 문항 타입 상위
                        importantAnswerFl: false, //필수답변 체크
                        delFl: this.$_enums.common.stringUsedFlag.NO.value, //flag
                        surveyQuestionRegs: [
                            {
                                answerOrd: 1,
                                id: null, //보기 id
                                questionView: '', //보기 제목
                                delFl: this.$_enums.common.stringUsedFlag.NO.value,  //flag
                            }
                        ]
                    } */
				],
			},
			initFormData: {
				id: null, //id
				surNm: '', //설문 제목
				surProcessCd: null, //진행상태
				surStartDt: '', //설문 시작기간
				surEndDt: '', //설문 끝기간
				resultOpenFl: '', //결과 공개 여부
				sheetId: null, //설문 안내 id
				sheetName: '', //설문 안내 문구
				surveyTargets: [], //설문 대상자
				surveyQuestions: [
					//질문
					{
						id: null, //질문 id
						questionNm: '', //질문 제목
						questionTypeCd: null, //설문 문항 타입 하위
						questionGroupCd: null, //설문 문항 타입 상위
						importantAnswerFl: false, //필수답변 체크
						delFl: this.$_enums.common.stringUsedFlag.NO.value, //flag
						surveyQuestionRegs: [
							{
								answerOrd: 1,
								id: null, //보기 id
								questionView: '', //보기 제목
								delFl: this.$_enums.common.stringUsedFlag.NO.value, //flag
							},
						],
					},
				],
			},
			isSelectionStopped: true,
		};
	},
	computed: {
		surveyValidationGroup: function() {
			return this.$refs['surveyValidationGroup'].instance;
		},
	},
	methods: {
		onChangeDay() {
			if (this.formData.surStartDt && !this.formData.surEndDt) {
				this.formData.surEndDt = this.formData.surStartDt;
			}
			if (this.formData.surEndDt && !this.formData.surStartDt) {
				this.formData.surStartDt = this.formData.surEndDt;
			}
		},
		/** @description : 상담사 셀 호버 이벤트  */
		onCellHoverChanged(e) {
			const event = e.event;
			if (event.buttons === 1) {
				if (this.isSelectionStopped) {
					this.isSelectionStopped = false;
					this.selectedRange = {};
				}

				if (this.selectedRange.startRowIndex === undefined) {
					this.selectedRange.startRowIndex = e.rowIndex;
				}

				if (!this.selectedRange.startColumnIndex) {
					this.selectedRange.startColumnIndex = e.columnIndex;
				}

				this.selectedRange.endRowIndex = e.rowIndex;
				this.selectedRange.endColumnIndex = e.columnIndex;

				let start = Math.min(this.selectedRange.startRowIndex, this.selectedRange.endRowIndex);
				let end = Math.max(this.selectedRange.startRowIndex, this.selectedRange.endRowIndex);

				let indexes = [];
				for (let i = start; i <= end; i++) {
					indexes.push(i);
				}
				e.component.selectRowsByIndexes(indexes);
			} else {
				this.isSelectionStopped = true;
			}
		},
		/** @description : 상담사 클릭 이벤트  */
		onRowClick(e) {
			let keys = e.component.getSelectedRowKeys();
			let index = keys.indexOf(e.key);

			if (index > -1) {
				keys.splice(index, 1);
			} else {
				keys.push(e.key);
			}

			e.component.selectRows(keys);
		},
		onOpen() {
			this.modal.agent.popupVisible = true;
			this.modal.agent.selectedIdList = this.modal.agent.targetSelectedIdList;
		},
		onClose() {
			this.modal.agent.popupVisible = false;
			this.modal.agent.selectedIdList = [];
		},
		/** @description : 대상자 삭제 */
		onTargetDeleteData() {
			const selectedRowKeys = this.$refs.targetGridData.instance.getSelectedRowKeys();

			if (!selectedRowKeys?.length) {
				this.$_Msg(this.$_msgContents('COMMON.MESSAGE.CMN_NOT_SELECTED', { defaultValue: '대상이 선택되어 있지 않습니다.' }));
				return;
			}

			this.formData.surveyTargets.forEach(f => {
				if (selectedRowKeys.find(s => s.agtId == f.agtId)) {
					f.delFl = this.$_enums.common.stringUsedFlag.YES.value;
				}
			});
			this.targetGrid.dataSource = this.formData.surveyTargets.filter(d => d.delFl != this.$_enums.common.stringUsedFlag.YES.value);
			this.modal.agent.targetSelectedIdList = this.targetGrid.dataSource.map(item => item.agtId);
		},
		onQuestionGroupCdChange(idx) {
			this.formData.surveyQuestions[idx].questionTypeCd = null;
		},
		onSave(value) {
			if (!value.length) {
				return;
			}

			let currentTargets = this.targetGrid.dataSource;
			currentTargets.forEach(t => {
				if (value.findIndex(v => v.agtid == t.agtId) < 0) {
					t.delFl = this.$_enums.common.stringUsedFlag.YES.value;
				} else {
					t.delFl = this.$_enums.common.stringUsedFlag.NO.value;
				}
			});

			this.formData.surveyTargets = currentTargets;
			this.targetGrid.dataSource = currentTargets.filter(d => d.delFl != this.$_enums.common.stringUsedFlag.YES.value);

			let data = [];
			value.forEach(v => {
				if (this.targetGrid.dataSource.findIndex(t => v.agtid == t.agtId) < 0) {
					data.push({
						agtId: v.agtid,
						agtNm: v.agtNm,
						deptNmPath: v.deptNmPath,
						deptCd: v.deptCd,
						delFl: this.$_enums.common.stringUsedFlag.NO.value,
					});
				}
			});

			this.targetGrid.dataSource = [...this.targetGrid.dataSource, ...data];
			this.formData.surveyTargets = [...this.formData.surveyTargets, ...data];

			this.modal.agent.targetSelectedIdList = value.map(item => item.agtid);
			this.onClose();
		},
		/** @description: 설문 항목 추가 이벤트 */
		onAddItems() {
			isSelectData = true;

			let row = {
				id: null, //질문 id
				questionNm: '', //질문 제목
				questionTypeCd: null, //설문 문항 타입 하위
				questionGroupCd: null, //설문 문항 타입 상위
				importantAnswerFl: false, //필수답변 체크
				delFl: this.$_enums.common.stringUsedFlag.NO.value, //flag
				surveyQuestionRegs: [
					{
						answerOrd: 1,
						id: null, //보기 id
						questionView: '', //보기 제목
						delFl: this.$_enums.common.stringUsedFlag.NO.value, //flag
					},
				],
			};

			this.formData.surveyQuestions.push(row);
		},
		/** @description: 설문 항목 삭제 이벤트 */
		onRemoveItems(index) {
			if (this.formData.surveyQuestions[index].id == null) {
				this.formData.surveyQuestions.splice(index, 1);
			} else {
				this.formData.surveyQuestions[index].delFl = this.$_enums.common.stringUsedFlag.YES.value;
			}
			console.log(this.formData.surveyQuestions);
		},
		/** @description: 문항 추가 이벤트 */
		onAddAnswer(index) {
			let delCount = this.formData.surveyQuestions[index].surveyQuestionRegs.reduce((count, item) => {
				return item.delFl === this.$_enums.common.stringUsedFlag.NO.value ? count + 1 : count;
			}, 0);

			if (delCount >= 10) {
				this.$_Msg('문항은 10개까지 입력 가능합니다.');
				return false;
			}

			let row = {
				answerOrd: delCount + 1,
				id: null,
				questionView: '',
				delFl: this.$_enums.common.stringUsedFlag.NO.value,
			};

			this.formData.surveyQuestions[index].surveyQuestionRegs.push(row);
		},
		/** @description: 문항 삭제 이벤트 */
		onRemoveAnswer(index, data) {
			if (this.formData.surveyQuestions[index].surveyQuestionRegs[data.index2].id == null) {
				this.formData.surveyQuestions[index].surveyQuestionRegs.splice(index, 1);
			} else {
				this.formData.surveyQuestions[index].surveyQuestionRegs[data.index2].delFl = this.$_enums.common.stringUsedFlag.YES.value;
			}
			console.log(this.formData.surveyQuestions);
		},
		// 사용자 리스트 클릭
		onSelectionChanged(e) {
			this.tree.selectedRowKeys = e.selectedRowKeys;
		},
		/** @description: 데이터 조회 메서드 */
		async selectFormData() {
			if (!this.config.updateYn) {
				this.formData = this.$_commonlib.cloneObj(this.initFormData);

				return;
			}

			const params = { scheId: this.formData.id };

			const payload = {
				actionname: 'EWM_SURVEY_SCHEDULE_ITEM',
				data: params,
				loading: false,
				useErrorPopup: true,
			};

			const res = await this.CALL_EWM_API(payload);

			if (res.status === 200) {
				if (res.data.header.resCode === 'success') {
					let data = res.data.data[0];
					this.formData = data;
					this.formData.resultOpenFl =
						this.formData.resultOpenFl === this.$_enums.common.stringUsedFlag.YES.value
							? this.config.resultOpenFl[0]
							: this.config.resultOpenFl[1];
					this.targetGrid.dataSource = this.formData.surveyTargets;
					this.formData.surveyTargets.forEach(t => {
						t.deptNmPath = this.$_fetchCodesByDepth(this.codeMap['root_ewm_hr_dept'], 4).find(
							c => c.codeId === t.deptCd,
						).pathNmFull;
						this.modal.agent.targetSelectedIdList.push(t.agtId);
					});
					this.formData.surveyQuestions.forEach(d => {
						d.importantAnswerFl = d.importantAnswerFl === this.$_enums.common.stringUsedFlag.YES.value ? true : false;
						d.surveyQuestionRegs.forEach((reg, index) => {
							reg.answerOrd = index + 1;
						});
					});

					this.config.canModify =
						this.formData.surProcessCd === this.$_enums.ewm.surProcess.READY.value ||
						this.formData.surProcessCd === this.$_enums.ewm.surProcess.PROGRESS_TEMP.value ||
						this.formData.surProcessCd === this.$_enums.ewm.surProcess.PROGRESS_STOP.value;
				}
			}
		},
		/** @description: 설문작성 저장 메서드 */
		async onSaveData(check) {
			/* if (!this.formData.surNm || !this.formData.surStartDt || !this.formData.surEndDt || !this.formData.resultOpenFl || !this.formData.sheetName
                || this.formData.surveyQuestions.length == 0 || this.formData.surveyTargets.length == 0) {
                this.$_Msg('필수항목을 입력해주세요.');
                return;
            } */

			if (!vm.surveyValidationGroup.validate().isValid) {
				return false;
			}
			/* if (!e.validationGroup.validate().isValid) {
				return false;
			} */
			// if (!vm.searchValidationGroup.validate().isValid) {
			// 	return;
			// }

			for (const f of this.formData.surveyQuestions) {
				// FIXME : (EWM) 공통코드 하드 코딩
				// if (f.delFl == this.$_enums.common.stringUsedFlag.NO.value) {
				f.importantAnswerFl =
					f.importantAnswerFl === false
						? this.$_enums.common.stringUsedFlag.NO.value
						: this.$_enums.common.stringUsedFlag.YES.value;
				f.questionTypeCd = f.questionGroupCd == 1090 ? f.questionGroupCd : f.questionTypeCd;

				/* if (!f.questionNm || !f.questionGroupCd || !f.questionTypeCd) {
                        this.$_Msg('질문 항목을 모두 입력해주세요.');
                        return;
                    }

                    for (const q of f.surveyQuestionRegs) {
                        if (f.questionGroupCd == 1089 && !q.questionView) {
                            this.$_Msg('보기를 입력해주세요.');
                            return;
                        }
                    } */
				// }
			}

			this.formData.resultOpenFl =
				this.formData.resultOpenFl === '비공개'
					? this.$_enums.common.stringUsedFlag.NO.value
					: this.$_enums.common.stringUsedFlag.YES.value;
			this.formData.surProcessCd =
				check == 0 ? this.$_enums.ewm.surProcess.READY.value : this.$_enums.ewm.surProcess.PROGRESS_TEMP.value;

			if (!(await this.$_Confirm(this.$_msgContents('COMMON.MESSAGE.CMN_CFM_SAVE', { defaultValue: '저장하시겠습니까?' }), { title: `<h2>설문작성</h2>` }))) {
				return;
			}

			let params = this.formData;

			const payload = {
				actionname: 'EWM_SURVEY_SCHEDULE_SAVE',
				data: params,
				loading: true,
			};

			const res = await this.CALL_EWM_API(payload);

			if (isSuccess(res)) {
				this.$_Toast(this.$_msgContents('CMN_SUC_SAVE', { defaultValue: '정상적으로 저장되었습니다.' }));
				this.$router.push({ path: '/ewm/surveys/implementation/list' });
			} else {
				this.$_Toast(this.$_msgContents('CMN_ERROR', { defaultValue: '데이터 처리 중 오류가 발생하였습니다.' }));
			}
		},
		/** @description: 설문작성 취소 메서드 */
		onCancelFormData() {
			this.$router.push({ path: '/ewm/surveys/implementation/list' });
		},
		/** @description : 셀렉트 박스 초기화 */
		async initCodeMap() {
			this.codeMap = await this.$_getCodeMapList('root_ewm_hr_dept,root_ewm_sur_type');
		},
		/** @description : 라이프사이클 created시 호출되는 메서드 */
		createdData() {
			vm = this;
			isSelectData = false;

			this.reqParams = this.$store.getters.getDetailParams;
			// if (!this.reqParams) {
			//     this.$_goPrePage();
			//     return;
			// }
			this.config.updateYn = this.reqParams ? this.reqParams.updateYn : false;
			if (this.config.updateYn) {
				this.formData.id = this.reqParams.id;
			}

			this.initCodeMap().then(() => {
				this.codes.questionGroupCd.dataSource = this.$_fetchCodesByDepth(this.codeMap['root_ewm_sur_type'], 2);
				this.codes.questionTypeCd.dataSource = this.$_fetchCodesByDepth(this.codeMap['root_ewm_sur_type'], 3);

				this.codes.questionTypeChoiceCd = this.codes.questionTypeCd.dataSource.filter(f => f.parentId == 1089);
				this.codes.questionTypeScoreCd = this.codes.questionTypeCd.dataSource.filter(f => f.parentId == 1091);
			});
		},
		/** @description: 라이프사이클 mounted시 호출되는 메서드 */
		mountedData() {
			this.selectFormData();
		},
		/** @description: 라이프사이클 destroyed시 호출되는 메서드 */
		destroyedData() {
			this.$store.commit('setDetailParams', null);
		},
		commonCodes() {
			return this.$_enums.common;
		},
	},
	created() {
		this.createdData();
	},
	mounted() {
		this.mountedData();
	},
	updated() {
		//const surveyQuestionsContainer = document.getElementById('surveyQuestionsContainer');
		//surveyQuestionsContainer.scrollTop = surveyQuestionsContainer.scrollHeight;
	},
	destroyed() {
		this.destroyedData();
	},
};
</script>

<style scoped>
.ui-glid-box {
	padding: 20px 20px 20px 20px;
}

.ui-glid-box > div.fl,
.ui-glid-box > div.fr {
	border-right: 0;
}

.table_form td > div {
	display: inline-block;
	vertical-align: middle;
}

.table_form td .empty-box {
	width: 10px;
}

.tableThi {
	border: 1px solid #ced4da;
	border-radius: 40px;
	padding: 10px 0px 10px 20px;
}

.maxHeight {
	max-height: 26rem;
}
</style>
